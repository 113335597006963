<template>
    <div class="">
        <div class="card-table" :class="mode ? 'card__tableday' : 'card__tablenight'">
            <div class="card-table-body ifon-add-title-block">
                <el-row :gutter="20">
                    <el-col :span="12">
                        <div class="caz-blocks-sarcho-title">
                            <div :class="mode ? 'content__titleday' : 'content__titlenight'" class="content-title d-flex align-center mr-2">
                                {{ $t("message.deals") }}
                            </div>
                            <div class="block-sarche">
                                <div class="header__search">
                                    <crm-input
                                        :size="'small'"
                                        :class="mode ? 'input__day' : 'input__night'"
                                        :className="'w100'"
                                        v-model="filterForm.search"
                                        :icon="'el-icon-search'"
                                    ></crm-input>
                                </div>
                            </div>
									 
                        </div>
                    </el-col>

                   <el-col :span="12" class="flex-style text-right">
							<div class="d-flex f-end">
								<el-button @click="dealModalDrawer = true" type="primary" size="small">{{ $t('message.create_deal') }}</el-button>
							</div>
                    </el-col>
                </el-row>
            </div>
            <!-- end ifon-add-title-block -->

            <div class="card-table-header table-crm-smart">
                <table
                    class="table-my-code table-bordered"
                    :class="mode ? 'table__myday' : 'table__mynight'"
                    v-loading="loadingData"
                >
                    <thead>
                        <tr>
                            <th class="w50p" v-if="columns.id.show">
                                {{ columns.id.title }}
                            </th>

                            <th v-if="columns.name.show">
                                {{ columns.name.title }}
                            </th>

                            <th v-if="columns.user.show">
                                {{ columns.user.title }}
                            </th>

                            <th v-if="columns.filial.show">
                                {{ columns.filial.title }}
                            </th>

                            <th v-if="columns.money_amount.show">
                                {{ columns.money_amount.title }}
                            </th>

                            <th v-if="columns.currency.show">
                                {{ columns.currency.title }}
                            </th>

                            <th v-if="columns.dealStage.show">
                                {{ columns.dealStage.title }}
                            </th>

                            <th v-if="columns.created_at.show">
                                {{ columns.created_at.title }}
                            </th>

                            <th v-if="columns.updated_at.show">
                                {{ columns.updated_at.title }}
                            </th>

                            <th v-if="columns.settings.show">
                                {{ columns.settings.title }}
                            </th>
                        </tr>

                        <tr class="filter_sorche">
                            <th v-if="columns.id.show">
                                <el-input
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    clearable
                                    size="mini"
                                    v-model="filterForm.id"
                                    :placeholder="columns.id.title"
                                    class="id_input"
                                ></el-input>
                            </th>

                            <th v-if="columns.name.show">
                                <crm-input
                                    :placeholder="columns.name.title"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    v-model="filterForm.name"
                                ></crm-input>
                            </th>

                            <th v-if="columns.user.show">
                                <select-user
                                    :size="'mini'"
                                    :placeholder="$t('message.user')"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    :id="filterForm.user_id"
                                    v-model="filterForm.user_id"
                                >
                                </select-user>
                            </th>

                            <th v-if="columns.filial.show">
                                <select-filial
                                    :size="'mini'"
                                    :placeholder="columns.filial.title"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    :id="filterForm.filial_id"
                                    v-model="filterForm.filial_id"
                                >
                                </select-filial>
                            </th>

                            <th v-if="columns.money_amount.show">
                                <crm-input
                                    :placeholder="columns.money_amount.title"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    v-model="filterForm.money_amount"
                                ></crm-input>
                            </th>

                            <th v-if="columns.currency.show">
                                 <select-currency
                                    :size="'mini'"
                                    :placeholder="columns.currency.title"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    :id="filterForm.currency_id"
                                    v-model="filterForm.currency_id"
                                ></select-currency>
                            </th>

                            <th v-if="columns.dealStage.show">
                               <select-deal-stage
                                    :size="'mini'"
                                    :placeholder="columns.dealStage.title"
                                    :id="filterForm.deal_stage_id"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    v-model="filterForm.deal_stage_id"
                                >
                                </select-deal-stage>
                            </th>

                            <th v-if="columns.created_at.show">
                                <crm-date-picker
                                    :placeholder="columns.created_at.title"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    v-model="filterForm.created_at"
                                ></crm-date-picker>
                            </th>

                            <th v-if="columns.updated_at.show">
                                <crm-date-picker
                                    :placeholder="columns.updated_at.title"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    v-model="filterForm.updated_at"
                                ></crm-date-picker>
                            </th>

                            <th
                                class="settinW"
                                v-if="columns.settings.show"
                            ></th>
                        </tr>
                    </thead>

                    <transition-group name="flip-list" tag="tbody">
                        <tr
                            v-for="deal in list"
                            :key="deal.id"
                            class="cursor-pointer"
                        >
                            <td v-if="columns.id.show">
                                {{ deal.id }}
                            </td>

                            <td v-if="columns.name.show">
                                {{ deal.name }}
                            </td>

                            <td v-if="columns.user.show">
                                {{ deal.user ? deal.user.name : '' }}
                            </td>

                            <td v-if="columns.filial.show">
                                {{ deal.filial ? deal.filial.name : ''  }}
                            </td>

                            <td v-if="columns.money_amount.show">
                                {{ (deal.calculate_money === 'deal' ? deal.money_amount : deal.product_total) |formatNumber(1) }}
                            </td>

                            <td v-if="columns.currency.show">
                                {{ deal.currency ? deal.currency.symbol : ''  }}
                            </td>

                            <td v-if="columns.dealStage.show">
                                {{ deal.deal_stage ? deal.deal_stage.name : '' }}
                            </td>

                            <td v-if="columns.created_at.show">
                                {{ deal.created_at }}
                            </td>

                            <td v-if="columns.updated_at.show">
                                {{ deal.updated_at }}
                            </td>

                            <td
                                v-if="columns.settings.show"
                                class="settings-td"
                            >
                               <el-dropdown @command="dealSettings">
                                    <span class="el-dropdown-link more_icons">
                                    <i class="el-icon-arrow-down el-icon-more-outline"></i>
                                    </span>
                                    <el-dropdown-menu slot="dropdown" size="mini">      
                                        <el-dropdown-item :command="{action: 'update', deal: deal}" icon="el-icon-edit el-icon--left">       
                                            {{ $t('message.update') }}
                                        </el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </td>
                        </tr>
                    </transition-group>
                </table>
                <div class="my___pagination">
                    <crm-pagination
                        :class="mode ? 'pagination__day' : 'pagination__night'"
                        @c-change="updatePagination"
                        :pagination="pagination"
                    ></crm-pagination>
                    <!-- <Pagination /> -->
                </div>
            </div>

            <div class="app-modal app-modal__full">
               <el-dialog
						:visible.sync="drawerDealCreate"
						:fullscreen="true"
						ref="drawerDealCreate"
						@opened="drawerOpened('drawerDealCreateChild')"
						@closed="drawerClosed('drawerDealCreateChild')"
                >
                <div
                    @click="closeAddModel()"
                    class="
                    close-modal-stick
                    d-flex
                    align-center
                    p-fixed
                    text-white
                    p-2
                    font-bold
                    pointer
                    transition
                    "
                >
                    <div class="close-modal-icon text-center rounded-circle transition">
                    <i class="el-icon-close"></i>
                    </div>
                    <span class="close-modal-text text-uppercase ml-2"></span>
                </div>
                <UpdateDeal
                    ref="drawerDealCreateChild"
                    :board_id="selectedDeal.board_id"
                    :selectedDeal="selectedDeal"
                    :dealCreate="dealCreate"
                    drawer="drawerDealCreate"
                />
               </el-dialog>

					<!-- create deal modal -->
					<el-dialog
						custom-class="modal-w-100 tash-select"
						:visible.sync="dealModalDrawer"
						ref="drawerDealCreate"
						@opened="drawerOpened('drawerDealCreateChild')"
						@closed="drawerClosed('drawerDealCreateChild')"
						:fullscreen="true"
            	>
						<DealModal
							ref="drawerDealCreateChild"
							drawer="drawerDealCreate"
							:dealCreate="true"
						/>
            	</el-dialog>
					<!-- create deal modal -->
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import list from "@/utils/mixins/list";
import { mapGetters, mapActions } from "vuex";
import UpdateDeal from "@/views/deals/components/add-modal";
import selectUser from "@/components/inventory/select-users";
import selectDealStage from "@/components/inventory/select-dealStage";
import selectFilial from "@/components/inventory/select-filial";
import selectCurrency from "@/components/selects/select-currency";
import DealModal from "@/views/deals/components/add-modal";


export default {
    name: "archivedDeals",
    mixins: [list],
    components: {
        UpdateDeal,
        selectUser,
        selectFilial,
        selectDealStage,
        selectCurrency,
		  DealModal
    },
    data() {
        return {
            drawerDealCreate: false,
            selectedDeal: {},
            dealCreate: false,
				dealModalDrawer: false
        };
    },

    computed: {
        ...mapGetters({
            list: "deals/list",
            columns: "deals/columns",
            pagination: "deals/pagination",
            filter: "deals/filter",
            sort: "deals/sort",
            mode: "MODE"
        }),
        actions: function () {
            return ["edit", "delete"];
        },
        exhibition_id: function () {
            return this.$route.params.exhibition_id
        },
    },
    methods: {
        ...mapActions({
            updateList: "deals/index",
            setPagination: "deals/setPagination",
            updateSort: "deals/updateSort",
            updateFilter: "deals/updateFilter",
            updateColumn: "deals/updateColumn",
            updatePagination: "deals/updatePagination",
            show: "deals/show",
            empty: "deals/empty",
            delete: "deals/destroy",
            refreshData: "deals/refreshData",
        }),
        check: function (column, event) {
            this.updateColumn({ key: column, value: event });
        },
        fetchData() {
            const query = {...this.filter, ...this.pagination, ...this.sort, exhibition_id: this.exhibition_id };
            if (!this.loadingData) {
                this.loadingData = true;
                this.updateList(query).then(res => {
                    this.loadingData = false
                }).catch(err => {
                    this.loadingData = false
                });
            }
        },
        closeAddModel() {
            this.drawerDealCreate = false;
				this.dealModalDrawer = false;
        },
        dealSettings(command){
            if(command.action === 'update'){
                this.selectedDeal = command.deal;
                this.dealCreate = false;
                this.drawerDealCreate = true;
            }
        },
		   closeDrawer(drawer) {
				this.dealModalDrawer = false;
            if ( this.$refs[drawer] && _.isFunction(this.$refs[drawer].closeDrawer)) {
               this.$refs[drawer].closeDrawer();
            }
         },
    },
    beforeRouteLeave (to, from, next) {
        this.$store.commit('deals/EMPTY_ARCHIVED_DEALS_LIST');
        next()
    },
};
</script>

